import axios from "axios";
import {useState} from "react";
import { useHistory } from "react-router-dom";
import qs from 'query-string';
import { Toast } from "antd-mobile";

const promiseMap = new Map<string, Promise<any>>();

function useHttpService(errorMessageDuration: number = 3) {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const httpService = (httpMethod: 'get' | 'post') => (url: string, data?: object) => {

        let requestKey = httpMethod+'|'+url;
        if (data) {
            requestKey = requestKey + '|' + JSON.stringify(data);
        }
        let promise = promiseMap.get(requestKey)
        //合并并发的相同请求
        if (promise) {
            return promise;
        }

        promise = new Promise<any>((resolve, reject) => {
            const tokenValue =  localStorage.getItem('token');
            setLoading(true);
            axios({
                method: httpMethod,
                headers: {'X-Token': tokenValue || ''},
                url: url,
                params: httpMethod === 'get' ? data : null,
                data: data,
                paramsSerializer: params => {
                  return qs.stringify(params)
                },
                cancelToken: source.token
            }).then(resp => {
                setLoading(false)
                resolve(resp.data);
                promiseMap.delete(requestKey)
            })
            .catch(error => {
                setLoading(false);
                if (axios.isCancel(error)) {
                    console.log('Request canceled');
                } else if (error.response) {
                    const status = error.response.status;
                    if (status === 401) {
                        const loginPath = '/login';
                        console.log('401未登录,重定向...')
                        let redrectUrl = loginPath;
                        if (window.location.pathname !== loginPath) {
                            let search = window.location.search;
                            if (search) {
                                //TODO 这里是兼容性处理,待安卓端升级后可去除
                                search = search.replace(/\?token=[0-9a-z]+\?token=/, '?token=');
                            }
                            redrectUrl = `${redrectUrl}?redirect_uri=${encodeURIComponent(window.location.pathname+search)}`;
                            history.push(redrectUrl);
                        }
                    }
                    else if (status === 403) {
                        errorMessageDuration && Toast.fail("您没有此权限", errorMessageDuration);
                    }
                    else if (error.response.data) {
                        errorMessageDuration && Toast.fail(error.response.data.tips, errorMessageDuration);
                    }
                } else if (error.message === 'Network Error') {
                    errorMessageDuration && Toast.fail("网络错误,请检查您的网络.", errorMessageDuration);
                } else {
                    console.error(error.response)
                    errorMessageDuration && Toast.fail(error.message, errorMessageDuration);
                }
                reject(error);
                promiseMap.delete(requestKey)
            });
        });
        promiseMap.set(requestKey, promise);
        return promise;
    }

    const http = {get: httpService('get'), post: httpService('post')}

    return {http, loading, cancel: source.cancel};
}


export default useHttpService;


