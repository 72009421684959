import useHttpService from "../service/httpService";
import useGlobalState from "../GlobalState";

function useMetaService() {
    const {http} = useHttpService();
    const [user, setUser] = useGlobalState("user");

    const getCurrentUser = () => {
        return http.get('/api/user/current').then(setUser);
    };
    return {getCurrentUser}
}

export default useMetaService;