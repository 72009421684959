import React, {Suspense, useEffect} from 'react';
import { BrowserRouter as Router, Route,Switch, Redirect } from "react-router-dom";
import router from './router/router';
import { GlobalStateProvider } from './GlobalState';
import { LocaleProvider } from 'antd-mobile';
import Container from './router/Container';

const renderPage = (item) => {
  //const Layout = item.layout();
  if (item.component) {
    const Child = typeof(item.component) == 'string' ? React.lazy(() => import(`${item.component}`)) : item.component;
    const Page =  props => <Child {...props} />
    return <Route path={item.path} key={item.path} component={Page}/>
  } else if (item.to) {
    return <Redirect from={item.path} to={item.to}/>
  }
  return null;
}


function App() {
  return (
    <GlobalStateProvider>
      <LocaleProvider>
        <Router>
          <Container>
            <Suspense fallback={<div>加载中...</div>}>
              <Switch>{router.map(renderPage)}</Switch>
            </Suspense> 
          </Container>
        </Router>
      </LocaleProvider>
    </GlobalStateProvider>
  );
}

export default App;
