import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useGlobalState from '../GlobalState';
import router from './router';
import siteMeta from '../siteMeta';
import useMetaService from './metaService';

const Container = function({children}) {
  const {getCurrentUser} = useMetaService();
  const history = useHistory();
  const location = useLocation();
  const setTitle = useGlobalState('title')[1];
  const siteName = siteMeta.basic.title;

  const listenHandler = (loc, action) => {
    const pathname = loc.pathname;

    const currentRouter = router.filter(r => r.path === pathname)[0];
    let titleName = null;
    if (currentRouter) {
        titleName = currentRouter.title;
    }
    else {
        history.push('/index');
    }
    document.title = titleName ? (titleName + '-' + siteName) : siteName;
    setTitle(titleName);
  }

  useEffect(() => {
    if (location.pathname !== '/login') {
      getCurrentUser();
    }
    
    listenHandler({pathname: location.pathname});
    return history.listen(listenHandler);
  }, [])

  return children;
}

export default Container;