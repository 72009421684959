const siteMeta = {
  basic: {
    title: '字节矩阵',
    logo: '',
    theme: 'dark'
  },
  menu: [
    {
      label: "采购单",
      path: "/index",
      icon: "carry-out"
    },
    {
      label: "分部管理",
      path: "/branch",
      icon: "apartment",
      subMenu: [
        {
          label: "分部列表",
          path: "/list"
        },
        {
          label: "分部账号",
          path: "/users"
        },
        {
          label: "分部角色",
          path: "/roles"
        },
        {
          label: "权限管理",
          path: "/privileges"
        }
      ]
    },
    {
      label: "客户与供应商",
      icon: "solution",
      path: "/source",
      subMenu: [
        {
          label: "原材料类别管理",
          path: "/materials_source_categories"
        },
        {
          label: "客户管理",
          path: "/customers"
        },
        {
          label: "供应商管理",
          path: "/suppliers"
        }
      ]
    },
    {
      label: "场地与设备",
      icon: "shop",
      path: "/equipment",
      subMenu: [
        {
          label: "工厂管理",
          path: "/factories"
        },
        {
          label: "卸货位管理",
          path: "/park_areas"
        }
      ]
    },
    {
      label: "账号管理",
      icon: "team",
      path: "/account",
      subMenu: [
        {
          label: "用户管理",
          path: "/users"
        }
      ]
    }
  ]
}

export default siteMeta;